import { OutState } from '@/modules/out/out.api'
import requestResult from '@/common/request/request'

export const OutStateDescriber = {
  [OutState.REVIEWING]: {
    class: 'blue',
    text: 'In review',
    i18n: 'moneyhistory_5',
  },
  [OutState.PROCESSING]: {
    class: 'green',
    text: 'Processing',
    i18n: 'moneyhistory_6',
  },
  [OutState.SUCCESS]: {
    class: 'green',
    text: 'Transferred',
    i18n: 'moneyhistory_7',
  },
  [OutState.FAIL]: {
    class: 'red',
    text: 'Failed',
    i18n: 'moneyhistory_8',
  },
  [OutState.PASS]: {
    class: 'green',
    text: 'Approved',
    i18n: 'moneyhistory_9',
  },
  [OutState.REJECT]: {
    class: 'red',
    text: 'Rejected',
    i18n: 'moneyhistory_10',
  },
}

export const commitFeedback = requestResult('/api/recharge/commit/feedback')

export const uploadImg = requestResult<{url: string}>('/api/recharge/upload/feedback')
