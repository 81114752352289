
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import { updateAvatar } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import useRequest from '@/hooks/useRequest'
import { commitFeedback } from '@/pages/transaction/history'
import router from '@/router'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'Feedback',
  components: {
    PageWithHeader,
    Button,
    Icon,
    Pic,
  },
  setup () {
    const img = shallowRef('')
    const code = shallowRef('')
    const [imgCommit, progress] = useRequest(updateAvatar)

    const [commit, submitProgress] = useRequest(commitFeedback)

    const upload = () => {
      imgCommit().then(resp => {
        img.value = (resp as { url: string }).url
      }).finally(() => {
        progress.value = false
      })
    }

    const submit = () => {
      if (!code.value) {
        showAlert('please upload picture')
        return false
      } else if (!img.value) {
        showAlert('please input UTR or UPI Ref. No.')
        return false
      } else {
        commit({
          transactionId: code.value,
          url: img.value,
        }).then(() => {
          router.back()
        }).finally(() => {
          submitProgress.value = false
        })
      }
    }
    return {
      img,
      code,
      progress,
      submitProgress,
      upload,
      submit,
      translate,
    }
  },
})
