<template>
  <PageWithHeader>
    <template #title>
      <t path="submitfaileddeposit_2"></t>
    </template>
    <div class="px-12">
      <t path="submitfaileddeposit_3" class="c-text">For the withdrawal, you need to submit the photo of valid Tax Card or Passport </t>
      <div class="bg-white br-lg py-16 my-16">
        <input type="text" class="code-input px-12" :placeholder="translate('submitfaileddeposit_4')" v-model="code" >
      </div>
      <t path="submitfaileddeposit_5" class="c-text">Please upload a screenshot of the successful result of the deposit, which should include the exact time of the transfer and UTR information.</t>
      <div class="t-center bg-white br-lg p-16 mt-16 pb-32">
        <div class="preview br-sm upload-main">
          <Pic v-if="img" :src="img" style="width: 100%;height: 100%" />
          <Icon v-else name="plus" class="anchor" @click="upload" />
        </div>
        <Button icon="camera" class="upload f-bold f-lg br-sm" style="height: 32px;"
          @click="upload" :progress="progress">
          <t path="submitfaileddeposit_6" custom #="{td}">{{td || 'Upload'}}</t>
        </Button>
      </div>

      <Button class="sb primary block f-bold f-lg" style="height: 48px;"
        @click="submit" :progress="submitProgress">
        <t path="submitfaileddeposit_7" custom #="{td}">{{td || 'Submit'}}</t>
      </Button>
    </div>
  </PageWithHeader>
</template>

<script lang='ts'>
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import { updateAvatar } from '@/common/jsBridge.api'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import useRequest from '@/hooks/useRequest'
import { commitFeedback } from '@/pages/transaction/history'
import router from '@/router'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'Feedback',
  components: {
    PageWithHeader,
    Button,
    Icon,
    Pic,
  },
  setup () {
    const img = shallowRef('')
    const code = shallowRef('')
    const [imgCommit, progress] = useRequest(updateAvatar)

    const [commit, submitProgress] = useRequest(commitFeedback)

    const upload = () => {
      imgCommit().then(resp => {
        img.value = (resp as { url: string }).url
      }).finally(() => {
        progress.value = false
      })
    }

    const submit = () => {
      if (!code.value) {
        showAlert('please upload picture')
        return false
      } else if (!img.value) {
        showAlert('please input UTR or UPI Ref. No.')
        return false
      } else {
        commit({
          transactionId: code.value,
          url: img.value,
        }).then(() => {
          router.back()
        }).finally(() => {
          submitProgress.value = false
        })
      }
    }
    return {
      img,
      code,
      progress,
      submitProgress,
      upload,
      submit,
      translate,
    }
  },
})
</script>

<style scoped lang='scss'>
.preview {
  margin: 24px auto;
  width: 215px;
  height: 128px;
  border: 1px solid var(--color-grey);
}

.anchor {
  padding: 40px 0;
  color: var(--color-grey);
  font-size: 40px;
}

.upload {
  min-width: 167px;
  color: var(--color-primary);
  background: var(--color-primary-thin);
}

.code-input{
  width: 100%;
  height: 40px;
  background: #F4F6F8;
  border-radius: 4px;
  border: 1px solid #D5DEE8;
}

.sb{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
