/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/15
 * @description
 *   out.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import { InHistorySchema } from '@/modules/in/in.api'
import { YesOrNo } from '@/types'

export type OutChannel = {
  type: number;
  name: string;
  showName: string;
  icon: string;
}

export enum FAAccountType {
  BANK_OF_IN = 1, // 印度
  UPI,
  BANK_OF_VI, // 越南
  BANK_OF_JP, // 日本
  BANK_OF_TW, // 台湾
  BANK_OF_PH, // 菲律宾
  BANK_OF_TH, // 泰国
  BANK_OF_EUR, // 欧洲
  BANK_OF_ID = 10, // 印尼
  BANK_OF_TC, // 土耳其
  BANK_OF_BRA, // 巴西
  BANK_OF_MALA, // 马来西亚
  CRYPTOCURRENCIES,
  BANK_OF_HK,
  FPS_OF_HK,
  BINANCE,
  SKRILL,
  NETELLER,
  GERMANY, // 德国
}

export type FAAccount = OutChannel & {
  type: number;
  account: string;
}

export const read = requestResult<Array<OutChannel>>('/api/cashoutchannel/list')

export type OutRecord = {
  fee: number;
  currencyAmount: number;
}

export const create = requestResult<OutRecord>('/api/cashout/v2/add')

// 绑定出金账户
export const bindFA = requestResult('/api/cashout/bank/binding')

// 解绑出金账户
export const unbindFA = requestResult('/api/cashout/bank/unbinding')

export enum OutState {
  REVIEWING,
  PROCESSING,
  SUCCESS,
  FAIL,
  PASS,
  REJECT,
}

export type OutHistorySchema = {
  amount: number;
  createTime: string;
  status: OutState;
  channelName: string;
  remark: string;
}

// 提现历史记录
export const readOutHistory = requestResult<Array<InHistorySchema>>('/api/cashout/list')

export type Bank = {
  bankId: number;
  bankName: string;
  status: YesOrNo;
}

export const readBankList = requestResult<Bank[]>('/api/cashout/bank/list')

export type IDType = {
  name: string;
  type: number;
}

export const readIds = requestResult<{ list: IDType[] }>(
  '/api/cashout/type/id')

export const postId = requestResult('/api/cashout/id/info/binding')

export const readNetellerAccount = requestResult<{ email: string }>('/api/cashout/select/ntl/email')
